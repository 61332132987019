import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.scss";
import Dashboard from "./Pages/Dashboard";
import Login from "./Pages/Login";
import { useState } from "react";
import Sidebar from "./components/Sidebar";
import Blogspage from './Pages/Blogspage';
import Contacts from "./Pages/Contacts";
import Addblogs from "./Pages/Addblogs";




function App() {
  const boolean = localStorage.getItem("isAuthenticated")
  const [auth, setauth] = useState(boolean);

  function logOut () {
    setauth(false);
    localStorage.removeItem("isAuthenticated")
  }

  return (
    <div className="App">
      <BrowserRouter>
        {auth ? (
         <>
          <Sidebar logOut={logOut}  />
          <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/blogs" element={<Blogspage/>} />
              <Route path="/contact" element={<Contacts/>} />      
          </Routes>
         </>
        ) : (
       
          <Login auth = { ()=> setauth(true)} />
        
        )}
      </BrowserRouter>
    </div>
  );
}

export default App;
