import React, { useEffect, useState } from 'react'
import "../styles/Allblogs.scss"

import { MdEditDocument } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import axios from 'axios';

function Blogspage() {
  const [blogs,setblogs] = useState([])

  const fetchBlogs=()=>
  {
    axios.get(`https://backend.prajaktamarwaha.com/api/blogs/`)
    .then(res => setblogs(res.data))
    .catch( err => console.log(err))
  }
  const deletehandler=(id)=>{
        axios.delete(`https://backend.prajaktamarwaha.com/api/blogs/${id}`)
       .then(res => {alert("data deletd");fetchBlogs();})
       .catch(err => console.log(err))
  }
  useEffect(()=>{
    fetchBlogs();
  },[])
  return (
    <div className="allblogs-parent">
    <div className="heading">
      <h2>All blogs</h2>
      <div className="line"></div>
    </div>
    <div className="blogs-data">
      <table>
        <thead>
          <tr>
            <th>S.no</th>
            <th>Title</th>
            <th>Image</th>
            <th>Category</th>
            <th>Description</th>
            <th>Action</th>
          </tr>
        </thead>

        <tbody>
          {blogs.map((data,index)=>{return(
          <tr key={index}>
          <td>{index + 1}</td>
          <td>{data.title}</td>
          <td>
            <div className="blog-img" style={{background : `url(${data.image})`}}>

            </div>
          </td>
          <td>{data.Category.name}</td>
          <td className='blogs-content' dangerouslySetInnerHTML={{ __html: data.blog_content }} />
          <td> <button style={{display : "none"}}><MdEditDocument/></button> <button onClick={()=>deletehandler(data.id)}><MdDelete/></button> </td>
        </tr>
          )})}

        </tbody>


      </table>

    




    </div>

      
    </div>
  )
}

export default Blogspage
