import React, { useState } from "react";
import { IoIosArrowBack } from "react-icons/io";
import {Link, useLocation, useNavigate } from "react-router-dom"
import { RiContactsBookFill } from "react-icons/ri";
import { FaRectangleList } from "react-icons/fa6";
import { MdAddToPhotos } from "react-icons/md";
import { FaWindowRestore } from "react-icons/fa";

import "../styles/Sidebar.scss"





function Sidebar({logOut}) {
  
  
  const navigate = useNavigate();
const location = useLocation()
  return (
    <div className="sidebar expanded" >



  <h2>PR Admin Panel</h2>

      <div className="sidebar-btn-box">

        {/* <button className={dashbtn && "active"} onClick={dashclick}> <span>< FaWindowRestore/> </span> <span>Dashboard</span> </button> */}
        <Link to="/" className={location.pathname == '/' ? "active" : ''}> <span>< FaWindowRestore/> </span> <span>Dashboard</span> </Link>
        {/* <Link to="/addblogs" className={location.pathname == '/addblogs' ? "active" : ''}  > <span>< MdAddToPhotos/> </span>  <span>Add Blogs</span> </Link> */}
        <Link to="/blogs" className={location.pathname == '/blogs' ? "active" : ''} > <span> <FaRectangleList/> </span> <span>All Blogs</span> </Link>
        <Link to="/contact" className={location.pathname == '/contact' ? "active" : ''} > <span> <RiContactsBookFill/></span> <span>Contacts</span> </Link>
      
       

      </div>
      <button className="btn" onClick={logOut} > <span>Logout</span> </button>

    </div>
  );
}

export default Sidebar;
