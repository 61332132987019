import React, { useEffect, useState } from 'react'
import "../styles/Contact.scss"

import { MdEditDocument } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import axios from 'axios';

function Contacts() {
  const [contactdata, setcontactdata] = useState([])
  const fetchContact = () => {
    axios.get("https://backend.prajaktamarwaha.com/api/contacts/")
      .then(res => setcontactdata(res.data))
      .catch( )
  }

  useEffect(() => {
    fetchContact();
  }, []);

  const deletehandler = (id) => {
    const boolean = window.confirm("Are you sure ?")
    if (boolean) {
      axios.delete(`https://backend.prajaktamarwaha.com/api/contacts/${id}`)
        .then(res => { alert("data deleted"); fetchContact() })
        .catch()
    }

  }
  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString();
    return `${day}-${month}-${year}`;
  }
  return (
    <div className="allblogs-parent">
      <div className="heading">
        <h2>Contacts List</h2>
        <div className="line"></div>
      </div>


      <div className="blogs-data">
        <table>

          <thead>
            <tr>
              <th>S.no</th>
              <th>Name</th>
              <th>Number</th>
              <th>Email</th>
              <th>Message</th>
              <th>Date</th>
              <th>Action</th>
            </tr>
          </thead>

          <tbody>
            {contactdata.map((data, index) => {
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{data.name} </td>
                  <td>{data.mobile}</td>
                  <td>{data.email}</td>
                  <td> {data.message}</td>
                  <td> {formatDate(data.date)}</td>
                  <td> <button style={{ display: "none" }}><MdEditDocument /></button> <button onClick={() => deletehandler(data.id)}><MdDelete /></button> </td>
                </tr>
              )
            })}


          </tbody>


        </table>






      </div>


    </div>
  )
}

export default Contacts
