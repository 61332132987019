import React, { useState } from "react";
import { FaRegEye } from "react-icons/fa";
import { FaRegEyeSlash } from "react-icons/fa";
import "../styles/Login.scss"
import { useNavigate } from "react-router-dom";


function Login(props) {


  const [eyeopen, seteyeopen] = useState(false);

  const [inputval, setinputval] = useState();
  const [passval, setpassval] = useState();

  const Navigate = useNavigate()


  const handlelogin = (e) => {
    e.preventDefault();
    if (inputval === 'Pr_admin' && passval === 'PrTeam@Diwise') {
      props.auth();
      Navigate("/");
      localStorage.setItem("isAuthenticated", true)
    }
    else {
      alert("wrong Credentials !")
    }
  }

  return (
    <div className="login_parent parent full">
      <div className="left">
        <h1>Sign In</h1>
        <form action="#" onSubmit={handlelogin}>
          <div className="form-field">
            <input type="text" required id="name" onChange={e => setinputval(e.target.value)} placeholder="UserId" />
          </div>

          <div className="form-field">
            <input type={eyeopen ? "text" : "password"} required id="name" onChange={e => setpassval(e.target.value)} placeholder="Password" />
            <span id="pass_eye" onClick={() => seteyeopen(!eyeopen)}  >
              {eyeopen ? <FaRegEye /> : <FaRegEyeSlash />}
            </span>
          </div>
          <input type="submit" className="btn"  value="Login" />
        </form>
      </div>
      <div className="right"></div>
    </div>
  );
}

export default Login;
