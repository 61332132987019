import React, { useEffect, useState } from "react";
import "../styles/Dashbaord.scss";
import CountUp from 'react-countup';
import { Link } from "react-router-dom";
import axios from "axios";

function Dashboard() {
     const [blogs,setblogs] = useState([])
     const [contacts,setcontacts] = useState([])
     const fetchBlog=()=>{
      axios.get(`https://backend.prajaktamarwaha.com/api/blogs/`)
      .then(res => setblogs(res.data))
      .catch( err =>console.log(err))
     }
     const fetchContact=()=>{
      axios.get(`https://backend.prajaktamarwaha.com/api/contacts/`)
      .then(res => setcontacts(res.data))
      .catch( err =>console.log(err))
     }
     useEffect(()=>{
      fetchBlog();
      fetchContact();
     },[])
  return (
    <div className="dashboard-parent">
      <div className="heading">
        <h2>Dashboard</h2>
        <div className="line"></div>
      </div>


      <div className="main">


        <div className="box1 box"> <h1> <CountUp end={blogs.length}></CountUp> </h1> <p>Total Blogs</p> </div>
        <div className="box2 box"> <h1> <CountUp end={contacts.length}></CountUp> </h1> <p>Total Contact</p> </div>
      </div>





    </div>
  );
}

export default Dashboard;
